import { Grid, ProgressCircle } from '@adobe/react-spectrum';

const LoadingState = () => (
  <Grid
    alignContent="center"
    justifyContent="center"
    minHeight="100vh"
  >
    <ProgressCircle
      aria-label="Loading..."
      isIndeterminate
    />
  </Grid>
);

export default LoadingState;
